import React, { useRef, useState } from 'react';
import './Verification.css';
import logo from './../../assets/SmartMedicslogo.png';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { generateOtp, verifyOtp } from '../../services/generateOtp';
function Verification({ setIsOtpVerified }) {
  const navigation =useNavigate()
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const[message,setmessage]=useState('');
  const [otp, setOtp] = useState('');
  const form = useRef();
  const enterOtp = useRef();
 
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleMobileChange = (e) => {
    const input = e.target.value;
    if (/^\d*$/.test(input)) {
      setMobile(input.slice(0, 11));
    }
  };

  const handleOtpChange = (e) => {
    const input = e.target.value;
    if (/^\d*$/.test(input)) {
      setOtp(input.slice(0, 6)); // Restrict OTP to 6 digits
    }
  };

  const otpvalidation = async (e) => {
    e.preventDefault();
    try {
      const response = await verifyOtp(email, otp); // Send email and OTP to verify
      console.log("API Response:", response); // Log the response
      if (response.success) {
        setError('');
        // alert("OTP Verified Successfully");
        setmessage("OTP Verified Successfully")
        setIsOtpVerified(true); // Set OTP verification status to true
        navigation('/preSorted-order'); // Redirect to protected route
      } 
    } catch (error) {
      setError('Invalid OTP. Please try again.');
      console.error("Error in OTP verification:", error); // Log the error for debugging
    }
  };
  
  const showOtp = async (e) => {
    e.preventDefault();
    if (!mobile && !email) {
      setError('Please fill in either your mobile number or email address.');
      return;
    }
    if (mobile && mobile.length < 11) {
      setError('Please enter a valid mobile number.');
      return;
    }
    if (email && !validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    if (mobile && email) {
      setError('Please fill in either your mobile number or email address.');
      return;
    }
    setError('');
    form.current.className = "d-none";
    enterOtp.current.className = "d-block";
    try {
      const response = await generateOtp(email); // Call the separate API function with FormData
      if (response.success) {
        console.log(response);
        localStorage.setItem("otpEmail", email);
        form.current.className = "d-none";
        enterOtp.current.className = "d-block";
        // alert('An OTP has been sent to your email.');
        setmessage("An 6 digit OTP has been sent to your email.")
 
      }
    } catch (error) {
      
      setmessage('Failed to send OTP. Please try again.');

    }
  };

  return (  
    <>  
      <div className='gradient-background overflow-hidden'>
        <div className='row col-md-12 d-flex justify-content-center mt-5'>
          <img style={{ width: 150 }} src={logo} alt='logo' className='pt-5' />
        </div>

        <div className='d-flex flex-column justify-content-center text-center'>
          <h1 className='fs-2 pt-4 fw-bold'>
            <span>Step into the future of </span>
            <span>convenient healthcare today.</span>
          </h1>
        </div>

        {/* --------------------------FORM-------------------------------------- */}     
        <div className='d-flex justify-content-center d-block' ref={form}>
          <Form onSubmit={showOtp}>
            <div className='row mt-2'>
              <Form.Group controlId="formMobile">
                <p className='text-white pt-3 text-center'>Please enter your 11-digit mobile number</p>
                <Form.Control 
                  type="tel" 
                  inputMode="numeric" 
                  pattern="[0-9]*"
                  placeholder="Mobile Number" 
                  value={mobile} 
                  onChange={handleMobileChange}
                  className='w-sm-100 bg-transparent border-2 border-white textbox' 
                />
              </Form.Group>
            </div>

            <div className='row pt-3 fs-6 justify-content-center'>
              OR
              <p className='text-white text-center'>Please enter your Email Address</p>
            </div>
            <div className='row'>
              <Form.Group controlId="formEmail">
                <Form.Control 
                  type="email" 
                  placeholder="Email Address" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)}
                  className='w-sm-100 bg-transparent border-2 border-white textbox' 
                />
              </Form.Group>
            </div>

            {error && (
              <p className="text-danger text-center mt-2">{error}</p>
            )}

            <Button variant="" type="submit" className='w-100 button mt-3'>
              Generate Pin
            </Button>
          </Form>
        </div>
{/* -------------------------------------------------------------- */}
        <div className='row d-none' ref={enterOtp}>
          <div className='d-flex justify-content-center'>
            <Form onSubmit={otpvalidation} className='pt-5'>
              <p className='text-white text-center'>{message}</p>
              <div className='row mt-1'>
                <Form.Group controlId="formOtp">
                  <Form.Control 
                    type="text" 
                    value={otp}
                    onChange={handleOtpChange} // Update OTP state on change
                    placeholder="OTP Number" 
                    required 
                    className='w-sm-100 bg-transparent border-2 border-white textbox' 
                  />
                </Form.Group>
              </div>
              {error && (
                <p className="text-danger text-center mt-2">{error}</p>
              )}
              <Button variant="" type="submit" className='w-100 button mt-3 h-25'>
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Verification;
