import React, { useEffect, useRef } from 'react';
import '../App.css';
import Images from '../assets/Images';
import { Link } from 'react-router-dom';
import CustomNavbar from '../Components/CustomNavbar';
import { Col, Container, Row, Button, Image } from 'react-bootstrap';
import { FaCheckCircle ,FaPlay} from 'react-icons/fa'; // Removed unused icons for clarity
import Footer from '../Components/Footer';
import logo from './../assets/SmartMedicslogo.png'
function Home() {


  const videoref=useRef(null)
  useEffect(()=>{
     if(videoref.current){
      videoref.current.playbackRate = 0.75;
     }
  },[])
  return (
    <>
  
      <CustomNavbar />
      {/* 247882 */}
      <Container fluid className='overflow-x-hidden' >
        {/* Section 1 - Full-Screen Video Background Section */}
       
        <div className='responsive-height' style={{ position: 'relative',  overflow: "hidden" }}>
  {/* Responsive video, aligned to the right */}
  <div
    style={{
      position: 'absolute',
      top: 0,
      right: 0, // Align the video to the right
      display: 'flex',
      justifyContent: 'flex-end', // Align video content to the right within the container
      alignItems: 'center', // Vertically center the video within the container
      width: '100%',
      height: '100%',
      // zIndex: '-1',
    }}
  >
    <video
      ref={videoref}
      src={Images.HomeVideo}
      autoPlay
      muted
      playsInline
      className="d-none d-md-block"
      style={{
        width: '70%', // Reduce the video width to 70%
        height: 'auto', // Maintain aspect ratio
        objectFit: 'cover', // Ensure the video fills its container while maintaining aspect ratio
        borderRadius: '10px', // Optional: rounded corners for a softer look
      }}
    />
  </div>

  {/* Content Overlay */}
  <Container
    fluid
    className="ps-5 h-100 d-flex align-items-center"
    style={{ position: 'relative', zIndex: '1', overflow: "hidden" }}
  >
    <Row className="w-100 text-center text-md-start">
    
      <Col
        xs={12}
        md={6}
        className="d-flex  ms-md-5 flex-column justify-content-center align-items-md-start"
        style={{ paddingTop: '0', marginTop: '0' }} // Remove extra padding and margin
      >
        <h1
          className="fs-2 "
          style={{ color: '#00909D', fontSize: '2rem', lineHeight: '1.2' }} // Adjust line-height to reduce vertical spacing
        >
          Your Medication, Carefully Arranged and Delivered
        </h1>
        <p
          className="fs-5"
          style={{ color: '#00909D', marginBottom: '0.5rem' }} // Reduce bottom margin for less vertical spacing
        >
          Simplify your medication routine with pre-sorted pills at no extra fee
        </p>
        <Container  className="d-md-none   pt-2 overflow-hidden">
          <div className="  text-center  ">
            <Image  src={Images.capsule} style={{ maxWidth: '350px' }} />
          </div>
        </Container>
        <div className="d-flex flex-row mt-1 mt-md-2 w-100 justify-content-center justify-content-md-start align-items-center">
          <Button
            variant="primary"
            style={{
              backgroundColor: 'black',
              borderColor: 'black',
              borderRadius: '50px',
            }}
            className="px-3 px-md-3 text-center"
          >
            Introducing Smart Medics Video
          </Button>

          <div
            className="ms-1   align-items-center justify-content-center playbtn"
         
          >
            <FaPlay />
          </div>
        </div>
      </Col>
    </Row>
  </Container>
</div>

        {/* Calendar Section */}
        <Container fluid className="mt-md-5  pe-md-5 mt-5  overflow-hidden">
          <Row className='pt-md-5'>
            <Col xs={12} md={6} className='text-center d-flex justify-content-center text-md-start order-1 order-md-0 '>
              <img
                src={Images.Calender}
                alt="calender"
                className="w-75 pt-md-3  pt-5"
              />
            </Col>
            <Col xs={12} md={6} className="align-content-center  text-center text-md-start  ">
            <h1
          className="fs-2 pt-2 pt-md-0"
          style={{ color: '#00909D', lineHeight: '1.2' }} // Adjust line-height to reduce vertical spacing
        >
         Let us handle sorting your medications by date and time
        </h1>
              {/* <h1 className="fs-2 " style={{ color: '#00909D', fontSize: '2rem',}}></h1> */}
              <p
          className="fs-5"
          style={{ color: '#00909D' }} // Reduce bottom margin for less vertical spacing
        >
                      No more waiting, sorting, or hunting for refills.

        </p>
              {/* <p className="fs-4"  style={{ color: '#00909D'}}>
              No more waiting, sorting, or hunting for refills.
              </p> */}
              <div className="d-flex justify-content-center justify-content-md-start">
                <Link to="#" className="text-decoration-none ">
                  <h3 className="pt-1 fs-5" style={{ color: '#00909D' }}>
                    See how it works
                  </h3>
                </Link>
                <div className="ps-3 pt-1 ">
                  <img src={Images.Rightarrow} alt="arrow" className="w-25" />
                  <img src={Images.Rightarrow} alt="arrow" className="w-25" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        {/* box  image section */}
        <Container fluid className="mt-md-5 mt-3 pe-md-4">
  <div className="d-md-flex pt-5 justify-content-md-start justify-content-center">
  
    <Col
      xs={12}
      md={6}
      className="align-content-center text-center mt-5 mt-md-5 text-md-start p-md-5 order-1 order-md-2"
    >
      <h1
        className="fs-2 mt-5  pt-md-0"
        style={{ color: '#00909D', fontSize: '2rem', lineHeight: '1.2' }}
      >
        We’ll bring your medications to you every week/month
      </h1>
      <div className="d-flex justify-content-center justify-content-md-start">
        <Link to="#" className="text-decoration-none">
          <h3 className="pt-1 fs-5" style={{ color: '#00909D' }}>
            See how it works
          </h3>
        </Link>
        <div className="ps-3 pt-1">
          <img src={Images.Rightarrow} alt="arrow" className="w-25" />
          <img src={Images.Rightarrow} alt="arrow" className="w-25" />
        </div>
      </div>
    </Col>

    {/* First Column on mobile, second on larger screens */}
    <Col
      xs={12}
      md={6}
      className="text-center mt-md-5 mt-5 text-md-start d-flex justify-content-center order-2 order-md-1"
    >
      <img src={Images.box} className="w-75" alt="box" />
    </Col>
  </div>
</Container>
                        {/* extra pharmacy section */}
                        <Container fluid className="mt-md-5 mt-3 pe-md-4">
  <div className="d-md-flex pt-5 justify-content-md-start justify-content-center">
  
    <Col
      xs={12}
      md={6}
      className="align-content-center text-center mt-5 mt-md-5 text-md-start p-md-5 order-1 order-md-2"
    >
      <h1
        className="fs-2 mt-5  pt-md-0"
        style={{ color: '#00909D', fontSize: '2rem', lineHeight: '1.2' }}
      >
      Add any extra pharmacy items you might need, like injections, inhalers, medicated creams, and other medical essentials
      </h1>
  
    </Col>

    {/* First Column on mobile, second on larger screens */}
    <Col
      xs={12}
      md={6}
      className="text-center mt-md-5 mt-5 text-md-start d-flex justify-content-center order-2 order-md-1"
    >
      <img src={Images.medicated} style={{width:"150%"}} className="" alt="box" />
    </Col>
  </div>
</Container>


        
       
        {/* Mobile Hand Section */}
        <Container fluid className="mt-md-5   mt-2 ps-md-5">
          <Row className='pt-5 '>
            <Col xs={12} md={6} className='ms-md-5  align-content-center'>
              <h1 className="fs-2 pt-5 pt-md-0  text-center text-md-start" style={{ color: '#00909D' }}>
              Smart Medics, Just a Tap Away
          
         
              </h1>
              <div className="pt-md-4 pt-3 fs-5 d-flex  justify-content-center justify-content-md-start">
                <ul style={{ color: '#00909D' }} className=''>
                  <li>Get timely updates about your medications</li>
                  <li>Contact our pharmacist for expert advice</li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={5} className="ms-2 pt-4 pt-md-0   d-flex justify-content-end">
              <img src={Images.mobile} alt="calender" className="w-75" />
            </Col>
          </Row>
        </Container>

        {/* SmartMedics logo Section */}
        <Container className='mt-5'>
          <Row className='d-flex justify-content-center pt-5'>
            <img src={logo} alt='logo'  className=' pt-5 w-50 logo'/>
            <h1 className='fw-bold fs-4 custom-text pt-2 justify-content-center d-flex'>Simpler. Safer. Smarter</h1>
            <h1 className='custom-heading-text fs-5 text-center pt-1 pt-md-4   '>We sort and package your medications in a spotless, regulated environment to <br/> maintain the highest safety and hygiene standards.</h1>
          </Row>
        </Container>

        {/* Pricing Section */}
        <Row
          className="justify-content-center mt-3"
          style={{ backgroundColor: '#00909D' }}
        >
          <Col
            xs={12}
            md={7}
            style={{ height: '500px' }}
            className="align-content-center"
          >
            <h1 className="text-lg-center pt-5 fs-2 text-white text-center">
            There’s no charge for our service  you just pay for the cost of your medications and delivery
            </h1>
            <div className="text-center">
            
            </div>
          </Col>
        </Row>

        {/* How We Deliver Section */}
        <Container fluid className="mt-5 ps-md-5 overflow-hidden">
          <h1 className="text-center pt-5 fs-2 "  style={{ color: '#00909D'}}>
            HOW WE DELIVER
          </h1>
          <Row className="align-items-center mt-5 mt-md-0">
            <Col xs={12} md={6} className="pt-4 pt-md-0 d-flex justify-content-center justify-content-md-start ">
              <ul className="list-unstyled fs-5 ms-md-5  ">
                <li className="mb-3 ">
                  <FaCheckCircle style={{ color: '#00909D' }} /> Express Delivery
                </li>
                <li className="mb-3 ">
                  <FaCheckCircle style={{ color: '#00909D' }} /> Cash On Delivery
                </li>
                <li className="mb-3 ">
                  <FaCheckCircle style={{ color: '#00909D' }} /> Real-Time Tracking
                </li>
                <li className="mb-3 ">
                  <FaCheckCircle style={{ color: '#00909D' }} /> Delivery Notification
                </li>
                <li className="mb-3 ">
                  <FaCheckCircle style={{ color: '#00909D' }} /> Flexible Delivery Options
                </li>
              </ul>
            </Col>
            <Col xs={12} md={6} className='text-center'>
              <img src={Images.deliverybike} alt="delivery truck" className="w-100 " />
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
}

export default Home;
