// src/services/apiService.js

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const generateOtp = async (email) => {
  const formData = new FormData();
  formData.append('email', email);

  try {
    const response = await axios.post(`${API_BASE_URL}/otp/generate`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('generateOtp Response:', response);
    return response.data;
  } catch (error) {
    console.error('Error generating OTP:', error);
    throw error;
  }
};
// / Function to verify OTP
export const verifyOtp = async (email, otp) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('otp', otp);

  try {
    const response = await axios.post(`${API_BASE_URL}/otp/verify`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(response);
    
    return response.data;
  } catch (error) {
    console.error('Error verifying OTP:', error);
    throw error;
  } 
};