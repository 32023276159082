// src/services/sendOrderEmail.js

import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;



export const sendOrderEmail = async (email) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/orders/${email}`);
    console.log("API Response:", response.data); // Log response for debugging
    return response.data;
  } catch (error) {
    console.error('Error sending order email:', error.response?.data || error.message); // Log detailed error
    throw error;
  }
};
export const cancelOrder = async (email, orderId) => {
  try {
    // Make the API call to cancel the order
    const response = await axios.post(
      `${API_BASE_URL}/cancel-order`,
      {
        email,
        orderId: String(orderId), // Convert orderId to a string

        cancelledBy: 'Customer', // Indicate who canceled the order
      },
      {
        headers: {
          'Content-Type': 'application/json', // Specify JSON payload
        },
      }
    );

    console.log('Order cancellation response:', response.data);
    return response.data; // Return the API response data
  } catch (error) {
    console.error('Error while canceling the order:', error.response?.data || error.message);
    throw error; // Re-throw the error to handle it in the calling function
  }
};