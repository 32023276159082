import React, { useState, useRef, useEffect } from 'react';
import { Button, Container, Form, Alert, Row } from 'react-bootstrap';
import CustomNavbar from '../../Components/CustomNavbar';
import Footer from '../../Components/Footer';
import './PrescriptionOrder.css';
import { submitOrderForm } from '../../services/PreSortedOrder';
import Swal from 'sweetalert2';
import Images from '../../assets/Images';
import { Dropzone } from '../../Components/Dropzone';
import { useNavigate } from 'react-router-dom';
import { sendOrderEmail } from '../../services/HistoryOrder';

export default function PrescriptionOrder() {
  const [files, setFiles] = useState([]);
  const otpEmail = localStorage.getItem("otpEmail");

  const navigate=useNavigate();
  const [isForSomeoneElse, setIsForSomeoneElse] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    durationType: '',
    durationNumber: '',
    orderingFor: 'myself',
    patientName: '',
    relationToPatient: '',
    images: [],
    nonPrescriptionMedicine: '',
    specialInstructions: '',
  });

  const [error, setError] = useState('');
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const addressRef = useRef(null);
  const imagesRef = useRef(null);

  const handleFileUpload = (files) => {
    setFormData((prev) => ({ ...prev, images: files }));
  };
  const handleOrderingForChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, orderingFor: value });
    setIsForSomeoneElse(value === "someoneElse");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};
    let firstErrorRef = null;

    if (!formData.firstName.trim()) {
      validationErrors.firstName = 'First name is required.';
      firstErrorRef = firstErrorRef || firstNameRef;
    }
    if (!formData.lastName.trim()) {
      validationErrors.lastName = 'Last name is required.';
      firstErrorRef = firstErrorRef || lastNameRef;
    }
    if (!formData.phone.trim()) {
      validationErrors.phone = 'Phone number is required.';
      firstErrorRef = firstErrorRef || phoneRef;
    }
    // if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
    //   validationErrors.email = 'A valid email is required.';
    //   firstErrorRef = firstErrorRef || emailRef;
    // }
    if (!formData.address.trim()) {
      validationErrors.address = 'Address is required.';
      firstErrorRef = firstErrorRef || addressRef;
    }
    if (!formData.images || formData.images.length === 0) {
      validationErrors.images = 'Please upload an image of your prescription.';
      firstErrorRef = firstErrorRef || imagesRef;
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (firstErrorRef && firstErrorRef.current) {
        firstErrorRef.current.scrollIntoView({ behavior: 'smooth' });
        firstErrorRef.current.focus();
      }
      return;
    }

    setErrors({});

    try {
      const confirmOrder = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to place this order?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00909D",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, place order!"
      });

      if (confirmOrder.isConfirmed) {
        const finalFormData = { ...formData, email: otpEmail };
        const response = await submitOrderForm(finalFormData);
        console.log('Order successfully submitted:', response);
        setFiles([]);
        Swal.fire({
          title: "Order Submitted Successfully!",
          text: "You will be notified shortly.",
          icon: "success",
          confirmButtonColor: "#00909D",
        });

        // Reset the form after successful submission
        setFormData({
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          address: '',
          durationType: '',
          durationNumber: '',
          orderingFor: 'myself',
          patientName: '',
          relationToPatient: '',
          images: [],
          nonPrescriptionMedicine: '',
          specialInstructions: '',
        });

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    } catch (error) {
      console.error('Error during form submission:', error);
      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text: "There was an issue submitting your order. Please try again later.",
      });
    }
  };
  // const handleViewPreviousOrders = async () => {
  //   try {
  //     const response = await sendOrderEmail(otpEmail);
  //     console.log("Orders fetched successfully:", response);
  //     navigation('/history', { state: { orders: response } }); // Pass orders data to OrderHistory
  //   } catch (error) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Failed to Fetch Orders",
  //       text: "Could not fetch orders. Please try again later.",
  //     });
  //   }
  // };
  return (
    <>
      <CustomNavbar />
      <div className='row overflow-hidden'>
        <img className='' src={Images.NonSortedBg} alt='logo' />
      </div>
      <div className='row mt-2 '>
      <div className='col-md-11 d-flex justify-content-end'>
      <Button className='historybtn' onClick={() => navigate('/history')}>View Previous Orders</Button>
      </div>
      </div>
      <Container className="mt-5" id='prescription'>
        <h6 id='error' style={{ color: "#00909D" }}>Please Enter Your Details</h6>
        
        {error && <Alert variant="danger">{error}</Alert>}
        <Form encType='multipart/form-data' onSubmit={handleSubmit}>
          <div className='row'>
            <Form.Group className="mb-3 col-md-6" controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                ref={firstNameRef}
                className={errors.firstName ? 'is-invalid' : ''}
              />
              {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
            </Form.Group>
            <Form.Group className="mb-3 col-md-6" controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                ref={lastNameRef}
                className={errors.lastName ? 'is-invalid' : ''}
              />
              {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
            </Form.Group>
          </div>
          <div className='row'>
            <Form.Group className="mb-3 col-md-6" controlId="formPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                ref={phoneRef}
                className={errors.phone ? 'is-invalid' : ''}
              />
              {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
            </Form.Group>
            <Form.Group className="mb-3 col-md-6" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                ref={emailRef}
                className={errors.email ? 'is-invalid' : ''}
              />
              {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </Form.Group>
          </div>
          <Form.Group className="mb-3" controlId="formAddress">
            <Form.Label>Delivery Address</Form.Label>
            <Form.Control
              as="textarea"
              name="address"
              value={formData.address}
              onChange={handleChange}
              ref={addressRef}
              rows={2}
              className={errors.address ? 'is-invalid' : ''}
            />
            {errors.address && <div className="invalid-feedback">{errors.address}</div>}
          </Form.Group>
          <div className='row'>
            <Form.Group className="mb-3 col-md-6">
              <Form.Label>Order Duration</Form.Label>
              <Form.Select
                name="durationType"
                value={formData.durationType}
                onChange={handleChange}
                required
              >
                <option value="" disabled>Select Duration</option>
                <option value="Days">Days</option>
                <option value="Weeks">Weeks</option>
                <option value="Months">Months</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 col-md-6">
              <Form.Label>Number of Duration</Form.Label>
              <Form.Select
                name="durationNumber"
                value={formData.durationNumber}
                onChange={handleChange}
                required
              >
                <option value="" disabled>Select Number</option>
                {[...Array(31).keys()].map(num => (
                  <option key={num + 1} value={num + 1}>
                    {num + 1}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
          <div className='row'>
            {/* Ordering Medication For */}
            <Form.Group className="mb-3 col-md-12">
              <Form.Label>Ordering Medication For</Form.Label>
              <Form.Select
                aria-label="Select ordering option"
                onChange={handleOrderingForChange}
              >
                <option value="myself">Myself</option>
                <option value="someoneElse">Someone Else</option>
              </Form.Select>
            </Form.Group>

            {/* Conditional fields if "Someone Else" is selected */}
            {isForSomeoneElse && (
              <>
                {/* Patient's Name */}
                <Form.Group className="mb-3 col-md-6">
                  <Form.Label htmlFor="patientName">Patient's Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="patientName"
                    placeholder="Enter patient's name"
                    required
                  />
                </Form.Group>

                {/* Relation to Patient */}
                <Form.Group className="mb-3  col-md-6">
                  <Form.Label htmlFor="relationToPatient">Relation to Patient</Form.Label>
                  <Form.Select id="relationToPatient" aria-label="Select relation" required>
                    <option value="">Select Relation</option>
                    <option value="parent">Parent</option>
                    <option value="spouse">Spouse</option>
                    <option value="child">Child</option>
                    <option value="friend">Friend</option>
                    <option value="other">Other</option>
                  </Form.Select>
                </Form.Group>
              </>
            )}
          </div>


          <Form.Group className="mb-3">
            <Form.Label>Upload Prescription</Form.Label>
            <Dropzone onFileUpload={handleFileUpload} files={files} setFiles={setFiles} />
            {errors.images && <div className="text-danger">{errors.images}</div>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Non-Prescription Medicine</Form.Label>
            <Form.Control
              as="textarea"
              name="nonPrescriptionMedicine"
              value={formData.nonPrescriptionMedicine}
              onChange={handleChange}
              rows={3}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Special Instructions</Form.Label>
            <Form.Control
              as="textarea"
              name="specialInstructions"
              value={formData.specialInstructions}
              onChange={handleChange}
              rows={3}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="I agree to receive refill reminders and subscribe to the Smart Medics newsletter for updates and offers."
         
            />
          </Form.Group>
          <Button type="submit" style={{ backgroundColor: "#00909D" }} className="w-100">
            Submit
          </Button>
        </Form>
      </Container>
      <Footer />
    </>
  );
}
