import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaLightbulb } from 'react-icons/fa';
import './CustomModal.css';
import { Link } from 'react-router-dom';

function CustomModal({ items, show, onClose }) {
    if (!show) return null;

    return (
        <div className="modal-overlay">
            
            <div className="modal-content">
            <button onClick={onClose} className="close-button ">&times;</button>

                {items.map((item, index) => (
                    <Row key={index} className="modal-item align-items-center py-2">
                        <Col xs="auto">
                            <img src={item.image} style={{ width:"60px", borderRadius: '5px' }} alt={item.label} />
                        </Col>
                        <Col className="text-center">
                        <Link to={item.link} className='text-decoration-none label'>
                        <p className="m-0 w-100">{item.label}</p>

                        </Link>
                        </Col>
                        <Col xs="auto" className='bulb'>
                            <FaLightbulb size={24} color={item.iconColor} />
                  
                            <p className='message-box'>{item.message}</p>
                        </Col>
                    </Row>
                ))}
            </div>
        </div>
    );
}

export default CustomModal;
