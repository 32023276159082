import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import AboutUs from './Screens/AboutUs';
import AllProducts from './Screens/AllProducts';
import ProductDetails from './Screens/ProductDetails';
import CheckOut from './Screens/CheckOut';
import 'react-toastify/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import PrescriptionOrder from './Screens/NonSortedOrder/PrescriptionOrder';
import PrivacyPolicy from './Screens/PrivacyPolicy';
import Conditions from './Screens/Conditions';
import DeliveryPolicy from './Screens/DeliveryPolicy';
import CancelationPolicy from './Screens/CancelationPolicy';
import Home from './Screens/Home';
import TrackOrder from './Screens/TrackOrder';
import OrderConfirmation from './Screens/OrderConfirmation';
import ScrollToTop from './Components/ScrollToTop';
import DropDown from './Components/CustomDropDown/DropDown';
import CustomModal from './Components/CustomModal/CustomModal';
import Verification from './Screens/VerificationScreen/Verification';
import ThankYouScreen from './Screens/ThankYouScreen/ThankYouScreen';
import OrderHistory from './Screens/OrdersHistory/OrderHistory';

function App() {
  const [isOtpVerified, setIsOtpVerified] = useState(false);
// Check localStorage for OTP verification state on load
useEffect(() => {
  const otpVerified = localStorage.getItem('isOtpVerified') === 'true';
  setIsOtpVerified(otpVerified);
}, []);

// Update localStorage whenever the state changes
useEffect(() => {
  localStorage.setItem('isOtpVerified', isOtpVerified);
}, [isOtpVerified]);

  return (
    <Router>
      <ScrollToTop/>
      <ToastContainer/>
      <Routes>
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/allproducts' element={<AllProducts />} />
        
        {/* Protected Route */}
        <Route 
          path='/preSorted-order' 
          element={isOtpVerified ? <PrescriptionOrder /> : <Navigate to="/verification" />} 
        />
        
        <Route path='/' element={<Home />} />       
        <Route path='/productdetails' element={<ProductDetails />} />
        <Route path='/checkout' element={<CheckOut />} />
        <Route path='/preSorted-order' element={<PrescriptionOrder />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='terms-conditions' element={<Conditions />} />
        <Route path='/delivery-policy' element={<DeliveryPolicy />} />
        <Route path='/cancellation-policy' element={<CancelationPolicy/>} />
        <Route path='/track-order' element={<TrackOrder/>} />
        <Route path="/order-confirmation" element={<OrderConfirmation />} />
        <Route path="/preSorted-order" element={<PrescriptionOrder />} />

     
        {/* Pass setIsOtpVerified to Verification for OTP verification */}
        <Route path="/verification" element={<Verification setIsOtpVerified={setIsOtpVerified} />} />
        
        <Route path="/thankyou" element={<ThankYouScreen />} />
        <Route path="/history" element={<OrderHistory />} />

      </Routes>
    </Router>
  );
}

export default App;
